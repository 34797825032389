import React from 'react';
import call from '../assets/images/call.webp';
import message from '../assets/images/message.webp';
import brandLogo from '../assets/images/newLogoEu.webp';
import { Box, Grid, Stack, Typography } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import '../styles/footer.scss';

// import { useTranslation } from 'react-i18next';
import FooterFlags from './FooterFlags/FooterFlags';

import { countryData } from '../pages/countries/countryData';

const footerCountriesArrays = [
    'austria',
    'austria',
    'austria',
    'slovakia',
    'slovakia',
    'slovakia',
    'czech-republic',
    'czech-republic',
    'czech-republic',
];

const QuickLinks = [
    { name: 'FAQs', url: 'faq' },
    { name: 'Contact Us', url: 'contact' },
    { name: 'FAQs', url: 'faq' },
    { name: 'Contact Us', url: 'contact' },
];

const Footer = () => {
    // const { t } = useTranslation();
    const { pathname } = useLocation();
    const customUrlString = link => {
        return `/${localStorage.getItem('i18nextLng')}/${link}`;
    };
    return (
        <Box bgcolor='#226db8' py={6} color='white' mt={10}>
            <Box sx={{ maxWidth: 'min(1600px, 85%)', mx: 'auto' }}>
                <Grid container spacing={4} justifyContent={'space-between'}>
                    <Grid item xs={12} lg={9}>
                        <Grid container spacing={2.5}>
                            <Grid item xs={12} md={7} lg>
                                <Typography variant='h6' fontSize={20} mb={1.5}>
                                    Countries
                                </Typography>
                                <Grid container spacing={2} sx={{ cursor: 'pointer' }}>
                                    {footerCountriesArrays.map((country, index) => (
                                        <Grid item xs={6} sm={4} key={index}>
                                            <FooterFlags
                                                imageSrc={countryData[country].flag}
                                                countryName={countryData[country].name}
                                            />
                                        </Grid>
                                    ))}
                                </Grid>
                            </Grid>
                            <Grid item xs={12} md={5} lg>
                                {/* QUick Links  */}

                                <Grid container spacing={2}>
                                    <Grid item xs={6} sm={4}>
                                        <Typography variant='h6' fontSize={20} mb={1.5}>
                                            Quick Links
                                        </Typography>

                                        <Stack spacing={1.5}>
                                            {QuickLinks.map((link, index) => (
                                                <Link
                                                    key={index}
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: 'inherit',
                                                        fontSize: 13,
                                                    }}
                                                    to={customUrlString(link.url)}
                                                    className={`${
                                                        pathname.includes(link.url)
                                                            ? 'activeNavLink'
                                                            : ''
                                                    }`}>
                                                    {link.name}
                                                </Link>
                                            ))}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Typography variant='h6' fontSize={20} mb={1.5}>
                                            Quick Links
                                        </Typography>

                                        <Stack spacing={1.5}>
                                            {QuickLinks.map((link, index) => (
                                                <Link
                                                    key={index}
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: 'inherit',
                                                        fontSize: 13,
                                                    }}
                                                    to={customUrlString(link.url)}
                                                    className={`${
                                                        pathname.includes(link.url)
                                                            ? 'activeNavLink'
                                                            : ''
                                                    }`}>
                                                    {link.name}
                                                </Link>
                                            ))}
                                        </Stack>
                                    </Grid>
                                    <Grid item xs={6} sm={4}>
                                        <Typography variant='h6' fontSize={20} mb={1.5}>
                                            Quick Links
                                        </Typography>

                                        <Stack spacing={1.5}>
                                            {QuickLinks.map((link, index) => (
                                                <Link
                                                    key={index}
                                                    style={{
                                                        textDecoration: 'none',
                                                        color: 'inherit',
                                                        fontSize: 13,
                                                    }}
                                                    to={customUrlString(link.url)}
                                                    className={`${
                                                        pathname.includes(link.url)
                                                            ? 'activeNavLink'
                                                            : ''
                                                    }`}>
                                                    {link.name}
                                                </Link>
                                            ))}
                                        </Stack>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* Quick Help */}
                    <Grid item xs={12} md='auto'>
                        <Stack direction='row' spacing={2} pb={3}>
                            <Link
                                to={customUrlString('')}
                                style={{ textDecoration: 'none', color: 'inherit' }}>
                                <img
                                    loading='lazy'
                                    style={{
                                        width: '43px',
                                        height: '42px',
                                        borderRadius: '100%',
                                    }}
                                    src={brandLogo}
                                    alt='carlogo'
                                    fetchPriority='low'
                                />
                            </Link>
                            <Stack spacing={1}>
                                <Typography variant='h4' fontWeight={300} pb={2.5}>
                                    vignetto.eu
                                </Typography>
                                <h2
                                    style={{
                                        color: 'white',
                                        fontSize: '20px',
                                        fontWeight: 'normal',
                                    }}>
                                    Need Help?
                                </h2>
                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}>
                                    <img
                                        loading='lazy'
                                        src={call}
                                        alt='call'
                                        fetchPriority='low'
                                        style={{ width: '15px' }}
                                    />
                                    <a
                                        href='tel:+34-8767859664'
                                        style={{ color: 'white', fontSize: '14px' }}>
                                        +34-8767859664
                                    </a>
                                </span>

                                <span
                                    style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        gap: '10px',
                                    }}>
                                    <img
                                        loading='lazy'
                                        src={message}
                                        alt='message'
                                        className='messageicon'
                                        fetchPriority='low'
                                        style={{ width: '15px' }}
                                    />
                                    <a
                                        href='mailto:help@vignette.com'
                                        style={{ color: 'white', fontSize: '14px' }}>
                                        help@vignette.com
                                    </a>
                                </span>
                            </Stack>
                        </Stack>
                    </Grid>
                </Grid>
            </Box>
        </Box>
    );
};

export default Footer;
