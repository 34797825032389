import React, { useMemo } from 'react';
import './footerFlags.scss';
import { Link } from 'react-router-dom';

const FooterFlags = ({ imageSrc, countryName }) => {
    const name = useMemo(() => countryName.trim().replace(' ', '-').toLowerCase(), [countryName]);

    return (
        <Link
            className='footerFlagContainer'
            to={`${localStorage.getItem('lng')}/country/${name}/checkout`}>
            <img loading='lazy' src={imageSrc} alt={countryName} />
            <div>
                <p>{countryName}</p>
            </div>
        </Link>
    );
};

export default FooterFlags;
