import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import MenuIcon from "@mui/icons-material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { useTranslation } from "react-i18next";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { styled } from "@mui/system";
import "../styles/appbar.scss";
import brandlogo from "../assets/images/newLogoEu.webp";
import english from "../assets/images/flags/english.webp";
import german from "../assets/images/flags/german.webp";
import czech from "../assets/images/flags/czech.webp";
import hungarian from "../assets/images/flags/hungarian.webp";
import italian from "../assets/images/flags/italian.webp";
import polish from "../assets/images/flags/polish.webp";
import slovak from "../assets/images/flags/slovak.webp";
import slovenian from "../assets/images/flags/slovenian.webp";
import ukrainian from "../assets/images/flags/ukrainian.webp";
import useNavigateCustom from "../hooks/useNavigateCustom";
import { Drawer } from "@mui/material";
import { IoMdClose } from "react-icons/io";


const CustomArrowIcon = styled(KeyboardArrowDownIcon)(({ theme }) => ({
  fill: "white", //custom styling
  fontSize: "32px",
  fontWeight: "500",
}));



const Appbar = () => {
  const languages = [
    { code: "en", name: "English", image: english },
    { code: "de", name: "German", image: german },
    { code: "pl", name: "Polish", image: polish },
    { code: "cs", name: "Czech", image: czech },
    { code: "sk", name: "Slovak", image: slovak },
    { code: "it", name: "Italian", image: italian },
    { code: "hu", name: "Hungarian", image: hungarian },
    { code: "sl", name: "Slovenian", image: slovenian },
    { code: "uk", name: "Ukrainian", image: ukrainian },
  ];

  const navigate = useNavigateCustom();
  const navigate2 = useNavigate();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [language, setLanguage] = useState("en");
  const { t, i18n } = useTranslation();
  const { pathname } = useLocation()
  const [appbarType, setAppbarType] = useState("appbar")
  const [scrolled, setScrolled] = useState(false);


  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);

  };
  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleLanguageClick = (event) => {
    localStorage.setItem("lng", event.target.value);
    setLanguage(event.target.value);
    let path = window.location.pathname.split("/");
    path[1] = event.target.value;
    path = path.join("/");
    navigate2(path);
    i18n.changeLanguage(event.target.value); // Update the language state with the selected value
  };
  const pages = [

    {
      name: t("homepage.faq"),
      url: "/faq",
    },
    {
      name: t("homepage.contactUs"),
      url: "/contact",
    },
  ];


  // Function to normalize the path by removing trailing slashes
  const normalizePath = (path) => {
    return path.replace(/\/+$/, '');
  };

  // Function to determine the AppBar type based on the normalized path
  const determineAppbarType = (path) => {
    const normalizedPath = normalizePath(path);
    const homeRegex = /^\/[a-z]{2}(\/country\/[^\/]+)?$/;

    if (homeRegex.test(normalizedPath)) {
      return "appbar";
    } else {
      return "nonHomeAppBar";
    }
  };



  // CONDITIONALLY SELECTING DIFFERENT STYLES OF APPBAR ON DIFFERENT PAGES
  useEffect(() => {
    setAppbarType(determineAppbarType(pathname));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);



  useEffect(() => {
    // Get the language stored in localStorage
    const storageLanguage = localStorage.getItem("lng");

    // Scroll Trigger
    const handleScroll = () => {
      if (window.scrollY > 0) {
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);


    // If a language is found in localStorage, set it as the language
    if (storageLanguage) {
      setLanguage(storageLanguage);
    }

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);



  return (
    <div className={`${appbarType} ${scrolled ? 'appbar-scrolled':''}`} maxWidth="xxl">
      <div className="appbar-container">
        <Toolbar>

          {/* BRAND LOGO & TITLE (mobile & desktop)  */}
          <Link to={`/${localStorage.getItem("i18nextLng")}`}>
            <div className="logo-container">
              <img loading="lazy" src={brandlogo} alt="" className="brand-logo" />
              <h3>vignetto.eu</h3>
            </div>
          </Link>


          {/* NAV LINKS  */}
          <Box
            className="pagesBtnCon"
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            {pages.map((page) => (
              < div
                key={page.name}
              >
                <Link
                  to={`/${localStorage.getItem("i18nextLng")}${page.url}`}
                  onClick={handleCloseNavMenu}
                  sx={{ color: "white" }}
                  className={`${pathname.includes(page.url) ? 'activeNavLink' : ""}`}
                >
                  {page.name}
                </Link>
              </div>
            ))}
          </Box>


          {/* LANGUAGE SELECTOR  */}
          <Box
            className="language-container"
            sx={{
              display: { xs: "none", md: "flex" },
            }}
          >
            <FormControl>
              <Select
                className="language-select"
                value={language}
                displayEmpty
                IconComponent={CustomArrowIcon}
                onChange={handleLanguageClick}
                sx={{ color: "white" }}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    <div
                      style={{ display: "flex" }}
                      className="drop-language-select"
                    >
                      <img loading="lazy" src={lang.image} alt={lang.name} />
                      <p style={{ marginLeft: "10px" }}>{lang.name}</p>
                    </div>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>




          {/* MOBILE VIEW  */}
          <Box className="mobilemenucon">

            {/*  LANGUAGE SELECT */}
            <FormControl
              className="mobile-form-control"
            >
              <Select
                id="mobile-language-select"
                className="language-select"
                value={language}
                displayEmpty
                IconComponent={CustomArrowIcon}
                onChange={handleLanguageClick}
                sx={{ color: "white" }}
              >
                {languages.map((lang) => (
                  <MenuItem key={lang.code} value={lang.code}>
                    <div
                      style={{ display: "flex", color: "black" }}
                      className="drop-language-select"
                    >
                      <img loading="lazy" src={lang.image} alt={lang.name} />
                      <p style={{ marginLeft: "10px" }}>
                        {lang.code.toUpperCase()}
                      </p>
                    </div>
                  </MenuItem>
                ))}
              </Select>

            </FormControl>


            {/* HAMBURGER ICON  */}
            <IconButton
              size="large"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
            >
              <MenuIcon className="menuicon" />
            </IconButton>


            {/* NAV DRAWER */}
            <Drawer
              variant="temporary"
              anchor="right"
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              className="mobile-nav-drawer"
            >

              <div className="drawer-header-container">
                <Link to={`/${localStorage.getItem("i18nextLng")}`}>
                  <div className="logo-container">
                    <img loading="lazy" src={brandlogo} alt="" className="brand-logo" />
                    <h3>vignetto.eu</h3>
                  </div>
                </Link>

                <IoMdClose size={20} onClick={handleCloseNavMenu} />

              </div>


              {/* NAV LINKS  */}
              {pages.map((page) => (
                <MenuItem
                  key={page.name}
                  onClick={() => {
                    handleCloseNavMenu();
                  }}
                  className="mobile-menu-item"
                >
                  <Link
                    to={`/${localStorage.getItem("i18nextLng")}${page.url}`}
                  >
                    <Typography>{page.name}</Typography>
                  </Link>
                </MenuItem>
              ))}
            </Drawer>


          </Box>


        </Toolbar>
      </div>
    </div >
  );
};

export default Appbar;
