import React from 'react'
import "../../styles/home/newBannerElement.scss"
import { useTranslation } from "react-i18next";
import verfied from "../../assets/images/verified.webp";
import fast from "../../assets/images/fast.webp";




const NewBannerElement = () => {
  const { t } = useTranslation();



  return (
    <div className='newBannerElementContainer'>

      <div className='newBannerElement'>

        {/* TOP HALF  */}
        <div>
          <p>{t("homepage.mission")}</p>
          <h2>{t("homepage.header")}</h2>
        </div>

        {/* BOTTOM HALF  */}
        {/* <div style={{display:"flex",flexDirection:"column",gap:"10px"}}>
          <div className="feature-sub-container1">
            <img loading="lazy" alt="fast" src={fast} />
            <h3>{t("homepage.verified")}</h3>
          </div>
          <div className="feature-sub-container1">
            <img loading="lazy" alt="" src={verfied} />
            <h3>{t("homepage.secure")}</h3>
          </div>
        </div> */}
       
        {/* <button>COMFORTIA</button> */}
      </div>


    </div>
  )
}

export default NewBannerElement