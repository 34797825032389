import { Suspense, useEffect, useState } from "react"
import FallbackCompLoader from "../FallbackCompLoader"
import SearchBar from "../SearchBar"
import HeaderCard from "./HeaderCard"
import BannerImage from "../../assets/images/newBannerImageUpscaled.webp"
import NewBannerElement from "./NewBannerElement"



const HomeTopComp = ({
    scrollToContriesGrid
}) => {

    const [scrolled, setScrolled] = useState(false);




    useEffect(() => {


        // Scroll Trigger
        const handleScroll = () => {
            if (window.scrollY > 0) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);


        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);



    return (
        // <div className={`home`} style={{}}>
            <div className={`home`} style={{ paddingBottom: `${scrolled ? '225px' : '189px'}` }}>


            <Suspense fallback={<FallbackCompLoader />} >
                <NewBannerElement />

                <div className="card-search-container" style={{ width: "50vw" }}>
                    {/* <HeaderCard scrollToContriesGrid={scrollToContriesGrid} /> */}
                    <SearchBar />
                </div>

            </Suspense>


            <div className="home-bannerImage">
                <img
                    fetchPriority="high"
                    alt="banner background"
                    src={BannerImage}
                    className="home-bannerImage"
                />

                <div className="imgFilter" />
            </div>


        </div>
    )
}
export default HomeTopComp